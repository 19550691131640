import { provideApolloClient } from '@vue/apollo-composable'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core'
import { createUploadLink } from 'apollo-upload-client'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  const httpLink = createUploadLink({
    // You should use an absolute URL here
    uri: config.public.httpEndpoint,
    headers: $cookies.get('apollo-token')
      ? {
        Authorization: `Bearer ${$cookies.get('apollo-token')}`
      }
      : null
  })

  const apolloClient = new ApolloClient({
    // Provide the URL to the API server.
    link: httpLink,

    // Using a cache for fast subsequent queries.
    cache: new InMemoryCache()
  })
  provideApolloClient(apolloClient);
  nuxtApp.vueApp.use(apolloClient)
})
