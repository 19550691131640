import { ElNotification } from 'element-plus'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      showError: (error, t = null) => {
        if (error?.length === 0) {
          $cookies.remove('apollo-token')
          window.location.href = '/login'
        } else if (error?.message && getErrorArray().some(i => error.message.includes(i))) {
          $cookies.remove('apollo-token')
          window.location.href = '/login'
        } else if (error?.message && error.message.includes('delete from')) {
          ElNotification({
            showClose: true,
            message: t('cannot_delete_entity_because_is_in_use'),
            type: 'error',
            customClass: 'error-notification',
            position: 'bottom-right',
            zIndex: 3000
          })
        } else if ((error?.message && error.message.includes('SQLSTATE')) || (error?.name === 'ApolloError' || JSON.stringify(error).includes('ApolloError'))) {
          ElNotification({
            showClose: true,
            message: t('an_error_has_ocurred_our_technicians_are_already_working_on_it_please_try_again_later'),
            type: 'error',
            customClass: 'error-notification',
            position: 'bottom-right',
            zIndex: 3000
          })
        } else if (error?.length && error[0].message && getErrorArray().some(i => error[0].message.includes(i))) {
          $cookies.remove('apollo-token')
          window.location.href = '/login'
        } else {
          ElNotification({
            showClose: true,
            message: error?.message ?? error,
            type: 'error',
            customClass: 'error-notification',
            position: 'bottom-right',
            zIndex: 3000
          })
        }
      }
    }
  }
})

function getErrorArray () {
  return ['Unauthenticated',
    'Su usuario no está autenticado',
    'The user is not authenticated',
    'Your user is not logged in',
    'Error en la petición, su usuario está inactivo',
    'Votre utilisateur n\'est pas connecté',
    'Las credenciales proporcionadas',
    '您的用户没有登录',
    '[]']
}
