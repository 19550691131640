import * as Sentry from '@sentry/browser'
export default defineNuxtPlugin((nuxtApp) => {
    const { public: { sentry } } = useRuntimeConfig()

    if (!sentry.dsn) {
        return {
            provide: {
                sentry: () => { }
            }
        }
    }

    Sentry.init({
        dsn: sentry.dsn,
        environment: sentry.environment,
        // Performance Monitoring
        tracesSampleRate: 0.1, // Change in production!
        // Set sampling rate for profiling - this is relative to tracesSampleRate
        profilesSampleRate: 0.1, // Change in production!
        ignoreErrors: [
            'Failed to fetch',
            'Non-Error promise rejection',
            '/_nuxt/builds/latest.json',
            'No error message',
            'file does not exist or is not readable',
            'Style is not done loading',
            'Unable to preload CSS',
            'delete from',
            'Unauthenticated',
            'Email could not be sent',
            'error loading dynamically imported module',
            'El contrato',
            'El fichaje',
            'La solicitud',
            'El empleado',
            'El tipo de',
            'La fecha',
            'El permiso',
            'Domain sandbox',
            '[]', 'Unexpected character', '{}',
            'La nueva contraseña no puede ser igual a la anterior',
            'No tiene permisos para realizar esta accion',
            'Ya existe un destino con ese orden',
            'Page not found', '404',
            'Authentication error', 'The user is not valid',
            'Operation no autorizada', 'You do not have permissions',
            'Credenciales incorrectas',
            'The new password cannot be the same as the old password',
            'No hemos podido iniciar sesión', 'We were unable to log you in',
            'Integrity constraint violation',
            'ResizeObserver loop limit exceeded'
        ]
    })

    return {
        provide: {
            sentry: (error, tag, data = null) => {
                Sentry.configureScope((scope) => {
                    scope.setTag('transaction', tag)
                    if (error?.graphQLErrors?.length) {
                        error.graphQLErrors.forEach((error, index) => {
                            Sentry.addBreadcrumb({
                                category: `graphQLErrors${(index + 1)}`,
                                data: {
                                    graphQLErrors: error, debugMessage: error.debugMessage ? error.debugMessage : error.message
                                }
                            })
                        })
                    }
                    if (data) {
                        Sentry.addBreadcrumb({
                            category: 'data',
                            data
                        })
                    }
                    Sentry.captureException(new Error(JSON.stringify(error)))
                })
            }
        }
    }
})